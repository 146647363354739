// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Link from "../../../../../../styleguide/components/Link/Link.res.js";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as $$Location from "../../../../../../models/Location.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../../../../styleguide/icons/IconSelect.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FetchLocationsDashboardRequest from "../../../../../../api/locations/requests/FetchLocationsDashboardRequest.res.js";
import * as LocationsDashboardOptionsScss from "./LocationsDashboardOptions.scss";

var css = LocationsDashboardOptionsScss;

function LocationsDashboardOptions(props) {
  var providerIds = props.providerIds;
  var sortBy = props.sortBy;
  var page = "page=" + String(props.pageNum);
  var providers = providerIds !== undefined && providerIds.length !== 0 ? "&" + (function (__x) {
          return Js_array.joinWith("&", __x);
        })(Belt_Array.map(providerIds, (function (id) {
                return "provider_ids[]=" + ID.toString(id);
              }))) : "";
  var query = Belt_Option.getWithDefault(Belt_Option.map(props.query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "CreatedAt" ? "&sort_by=locations.created_at&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
      variant === "Approved" ? "&sort_by=locations.is_approved&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
          variant === "ProviderName" ? "&sort_by=providers.name&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
              variant === "Name" ? "&sort_by=locations.name&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
                  variant === "LastApproved" ? "&sort_by=locations.last_approved_on&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : "&sort_by=locations.active&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL)
                )
            )
        )
    );
  var approvalStatuses = props.approvalStatuses.map(function (status) {
          return "&is_approved[]=" + $$Location.ApprovalStatus.toBoolString(status);
        }).join("");
  var csv = "/api/v1/dashboard/locations.csv?" + page + providers + query + sortBy$1 + approvalStatuses;
  var xls = "/api/v1/dashboard/locations.xlsx?" + page + providers + query + sortBy$1 + approvalStatuses;
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            className: css.ctaButton,
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsxs(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: css.popoverBody,
                      children: [
                        JsxRuntime.jsxs(Link.make, {
                              href: csv,
                              className: css.popoverBodyLink,
                              children: [
                                "Download CSV",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: css.popoverBodyLinkIcon
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(Link.make, {
                              href: xls,
                              className: css.popoverBodyLink,
                              children: [
                                "Download Excel",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: css.popoverBodyLinkIcon
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = LocationsDashboardOptions;

export {
  css ,
  make ,
}
/* css Not a pure module */
